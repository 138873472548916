<template>
  <div
    :style="{ width: width + 'px' }"
    class="AppDropdown"
    @keydown.down.prevent="down"
    @keydown.tab.prevent="down"
    @keydown.up.prevent="up"
  >
    <VDropdownOption
      v-for="(option, index) of options"
      :key="index"
      :option="option"
      :target="target"
      :index="index"
      :autofocus="autofocus"
      :withImg="withImg"
      :withWord="withWord"
      :selected="selected"
      :isProject="isProject"
      :withColorLabel="withColorLabel"
      @select="select"
      @focus="setTarget"
      @down="down"
      @up="up"
      :ref="`option-${index}`"
    />
  </div>
</template>

<script>
import VDropdownOption from '@/components/ui/VDropdown/VDropdownOption';

export default {
  name: 'VDropdown',
  components: {
    VDropdownOption,
  },

  props: {
    selected: {
      type: Object,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    withImg: {
      type: Boolean,
      default: null,
    },
    isProject: {
      type: Boolean,
      default: false,
    },
    withColorLabel: {
      type: Boolean,
      default: false,
    },
    withWord: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    target: null,
  }),

  mounted() {
    if (this.autofocus) {
      this.setTarget(0);
    }
  },

  methods: {
    select(value) {
      this.$emit('select', value);
    },
    setTarget(index) {
      this.target = index;
    },
    down() {
      if (this.target < this.options.length - 1 && this.target !== null) {
        this.setTarget(this.target + 1);
      } else {
        this.setTarget(0);
      }
    },
    up() {
      if (this.target) {
        this.setTarget(this.target - 1);
      } else {
        this.setTarget(this.options.length - 1);
      }
    }
  },
}
</script>

<style lang="scss">
.AppDropdown {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 3px 8px 0 rgba(158, 158, 158, 0.55);
  max-width: 300px;
  min-width: 185px;
  overflow-y: auto;
  max-height: 160px;
  padding: 7px 0;

  @include dark-theme {
    background: $darkThemeBgTwo;
    box-shadow: 0 6px 13px 0 rgba(25, 25, 25, 0.48);
  }
}
</style>
